body{
    position: relative;
    overflow: hidden;
    font-family: "MyriadProBold", "ByomBoldTrial";
}
html, body, #root, .App{
    height: 100%;
    font-family: "MyriadProBold", "ByomBoldTrial";
}

@font-face {
    font-family: 'MyriadProBold';
    src: local('MyriadProBold'), url(./fonts/MyriadProBold.woff) format('woff');
}

@font-face {
    font-family: 'ByomBoldTrial';
    src: local('ByomBoldTrial'), url(./fonts/ByomBoldTrial.woff) format('woff');
}